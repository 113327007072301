import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { Layout } from "../components/Layout"
import { DashboardSection } from "../style/dashboard"
import DashboardData from "../components/DashboardData";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePickerComp from "../components/DateRangePicker";
import Overlay from "../components/Overlay"

export default function Home() {
  const loginData = useSelector((state) => state.login.user.portalUserId)
  const user = useSelector((state) => state.login.user)
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  // const user = JSON.parse(localStorage.getItem("user"))
  //   ? JSON.parse(localStorage.getItem("user")).portalUserId
  //   : null
  const loginBool = Boolean(loginData)
  // console.log(loginData)
  useEffect(() => {
    if (!loginBool) {
      return navigate("/login");
    }
    // console.log("user from index", loginBool)
    console.log(range);
  }, [loginBool, range])

  return (
    <>
      <Layout>
        <DashboardSection>
          <h1>{!user.hasOwnProperty("organizationId") ? "Doctorite Dashboard" : user.organizationName + " Dashboard"}</h1>
          <DashboardData
            onCustomPress={(bool) => { setShowRangePicker(bool) }}
            range={range[0]}
            isRangeSelected={!showRangePicker}
          />
        </DashboardSection>
        {showRangePicker && <DateRangePickerComp
          rangeSelected={(dateRange) => { setRange(dateRange); }}
          onDonePressed={() => { setShowRangePicker(false); }}
          startDate={range[0].startDate}
          endDate={range[0].endDate}
        />}
        <Overlay open={showRangePicker} close={() => { setShowRangePicker(false) }} />
      </Layout>
    </>
  )
}
